.main[data-v-079f7fa0] {
  overflow: hidden;
  width: 100%;
  height: calc(100vh);
  background-color: #0e2241;
  color: #fff;
}
.main .main-card[data-v-079f7fa0] {
    background-color: #263955;
    border: none;
    height: calc(100vh - 180px);
    overflow-y: auto;
}
.main .main-header[data-v-079f7fa0] {
    height: 13.98148vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.main .main-content[data-v-079f7fa0] {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}
.main .main-content[data-v-079f7fa0] .el-menu-item {
      color: white !important;
      font-weight: bold;
      margin-top: 5px;
      border: 2px solid #3e9dfa;
}
.main .main-content[data-v-079f7fa0] .el-submenu__title {
      color: white !important;
      font-weight: bold;
      margin-top: 5px;
      border: 2px solid #3e9dfa;
}
.main .usual-column[data-v-079f7fa0] {
    height: 60px;
}
.main .usual-column .usual-item[data-v-079f7fa0] {
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      width: 200px;
      height: 35px;
      line-height: 35px;
      color: white;
      background-color: #0ac78c;
}
.main .usual-column .usual-item[data-v-079f7fa0]:hover {
      background-color: #0befa8ff;
}
